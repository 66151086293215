.alldiv {
  /* width: 1500px; */
  /* flex: 1px */
  /* width: auto */
  width: 100%;
  height: auto;
  max-width: 100vw;
  overflow-x: hidden;
}
.latTopdivheight {
  height: 50px;
}
.dotedlaptop {
  width: 100%;
}
.aboutuscomputer {
  width: 987px;
  height: 1480px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.heheight {
  height: 65px;
}
.barcodeimage {
  width: 100%;
}

.firstDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.freeOnline {
  display: flex;
  flex-direction: column;
  /* margin: 20px; */
}
.coveraboutustdesktop {
  width: 987px;
  height: 1480px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.items {
  width: 153px;
  height: 38px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #f52727;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.support {
  width: 114px;
  height: 38px;
  border-radius: "0px, 0px, 10px, 10px";
  background-color: #d3d3d3;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  margin-left: 5px;
}

.zero {
  color: #ffffff;
  font-size: "10px";
  font-weight: 700;
}

.catItem {
  color: #ffffff;
  font-size: "10px";
  font-weight: 700;
  margin-left: 10px;
}

.cartImage {
  width: 27px;
  height: 26.01px;
}
.yellowMobile {
  display: none;
}
.supporttext {
  color: #6d6060;
  font-size: "13px";
  font-weight: 700;
}
.connectinglaptop {
  height: 32px;
  margin-left: 30px;
}
.supportImage {
  margin-left: 5px;
}

.supportImage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.threeImage {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between */
}

.goupdivs {
  /* height: 330px; */
  width: 651px;
  /* justify-content: center; */
  display: flex;
  /* align-items: center; */
  align-content: center;
  flex-direction: column;
  margin-top: 50px;
  /* margin 20px; */
}
.goupdivsimage {
  height: 530.95px;
  width: 644.95px;
  /* justify-content: center; */
  display: flex;
  align-items: center;
  /* align-content: center; */
  flex-direction: column;
}

.currentTextIndex {
  text-align: left;
  margin: 0px;
}

.currentTextIndexOwena {
  text-align: left;
  margin: 0px;
}

.imagesize {
  height: 567.05px;
  width: 587.95px;
}

.itemsdiv {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}

.experience {
  color: #ffffff;
  margin: 0px;
  margin-left: 20%;
  font-size: 45px;
  font-weight: 700;
  text-align: right;
  margin-right: 135px;
}

.fivediv {
  display: flex;
  flex-direction: row;
  z-index: 2;
  margin-right: 90px;
  /* margin-left: -35% */
}

.divSize {
  width: 125px;

  height: 126.38px;
  background-color: #444444;
  margin: 5px;
  border-radius: 10px;
}

.divSizeww {
  width: 141.29px;

  height: 126.38px;
  background-color: #444444;
  margin: 5px;
  border-radius: 10px;
}
.custom-confirm-button {
  background-color: #ffdf73 !important;
}
.yellowTabLetDesktop {
  display: none;
}

.yellowDesktop {
  display: "flex";
  justify-content: center;
  align-content: center;
  align-items: center;
}
.amazing {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  /* margin-left: -200px; */
  /* align-items: center; */
}

.shopping {
  margin: 0px;
  color: #ffdf73;
  font-size: 45px;
  font-weight: 700;
  text-align: right;
}

.shoppings {
  color: #ffffff;
  font-size: 45px;
  font-weight: 700;
  margin-right: 135px;
}

.we {
  text-align: right;
  color: #ffffff;
  font-weight: 400;
  font-size: 20px;
  margin: 0px;
  margin-right: 135px;
}

.testes {
  margin: 0px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
}

.imagesss {
  margin: 10px;
}

.seconddiv {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
}

.itemsdi {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 50px;
}

.fivedi {
  display: flex;
  flex-direction: row;
  z-index: 2;
}

.threeimages {
  width: 609px;
  height: 524px;
}

.soon {
  text-align: left;
  color: #f4aa06;
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  margin-left: 5px;
}
.downloadApp2 {
  flex-direction: row;
  justify-content: center;
  display: flex;
  align-content: center;
}

.soon2 {
  text-align: center;
  color: #f4aa06;
  font-size: 25px;
  font-weight: 700;
  margin: 0px;
}
.downloadApp {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  align-content: flex-start;
}

.Connecting {
  text-align: center;
  color: #000000;
  font-size: 25px;
  font-weight: 600;
}

.promise {
  text-align: center;
  font-size: 45px;
  font-weight: 400;
  margin: 0px;
  color: #262424;
}
.Newsletterdiv {
  display: flex;
  flex-direction: column;
  align-content: center;
  display: flex;
  flex-direction: "column";
  align-items: flex-start;
}
.Newsletter {
  font-size: 20px;
  font-weight: 700;
  margin: 0px;

  /* align-self: center; */
  /* margin-left: 22.7% */
}

.Oweone1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.Oweon {
  width: 169px;
  height: 59px;
  align-self: center;
}

.createfree {
  font-size: 45px;
  font-weight: 700;
  color: #3c3724;
  margin: 0px;
}

.merchant {
  font-size: 45px;
  font-weight: 700;
  color: #ef8829;
  margin: 0px;
}

.enables {
  color: #3c3535;
  font-size: 20px;
  font-weight: 400;
}

.Subscribetext {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}
.allinputfiedls {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* background-color: #FADB96; */
  /* width: 912px;
    height: 65px; */
}

.allinputfiedl {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  background-color: #fadb96;
  width: 912px;
  height: 70px;
  border-radius: 5px;
}
.textinputfileds {
  width: 912px;
  height: 65px;
  background-color: #fadb96;
  border-width: 0px;
  border-radius: 5px;
  padding: 0 10px;
}
.buttons {
  width: 165px;
  height: 60px;
  background-color: #f4aa06;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.MobileViewLandingPage {
  display: none;
}
.seconddivMobile {
  display: none;
}
.lastofalllastDesktop {
  display: block;
  justify-content: center;
  align-content: center;
}
.lastofalllastMobileView {
  display: none;
}

/* CONTACT US CSS */
.div0 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 297px;
  background-color: #f4aa06;
  /* position: absolute; */
}
.div1 {
  width: 1022px;
  height: 297px;
  background-color: #f4aa06;
}
.div2 {
  width: 488px;
  height: 297px;
}
.dottedclass {
  display: flex;
  justify-content: center;
  margin-top: -30px;
}
.threeeimages {
  /* display: flex; */
  /* flex-direction: column; */
  margin: 30px;
}
.pAbouttss {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  line-height: 37.08px;
  opacity: 0.6;
  text-align: justify;
}
.allAboutUsspaage {
  display: block;
}

.allAboutUsspaageMobileView {
  display: none;
}

/* @media (min-width: 768px) and (max-width: 1024px) {
} */

.seconddivTablet {
  display: none;
}

@media screen and (max-width: 576px) {
  .seconddivTablet {
    display: none;
  }
  .alldivsss {
    display: none;
  }
  .MobileViewLandingPage {
    width: auto;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  }
  .currentTextIndexm {
    text-align: center;
    margin: 0px;
  }
  .currentTextIndexOwenam {
    text-align: center;
    margin: 0px;
  }
  .threeImage {
    display: none;
  }
  .barcodeimagedesktop {
    display: none;
  }
  .threeImageMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -90px;
  }
  .goupdivsMobile {
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
  }
  .goupdivsMobilewidth {
    width: 294.81px;
    height: 241.95px;
  }
  .imagesizeMobile {
    width: 294.81px;
    height: 241.95px;
  }
  .barcodeimageMobile {
    width: 100%;
    height: 52px;
  }
  .yellowDesktop {
    display: none;
  }
  .yellowTabLetDesktop {
    display: none;
  }
  .yellowMobile {
    display: flex;
  }
  .amazingMobile {
    display: flex;
    flex-direction: column;
  }

  .shopping {
    margin: 0px;
    color: #ffdf73;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  .shoppings {
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  .amzingandshopping {
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    /* background-color: #000000; */
    /* width: 279px; */
    /* height: 72px; */
  }
  .experience {
    color: #ffffff;
    margin: 0px;
    font-size: 30px;
    font-weight: 700;
  }
  .wewe {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .we {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }
  .threethreemobile {
    width: auto;
    height: 234px;
  }
  .testes {
    margin: 0px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    padding: 5px;
  }
  .testess {
    margin: 0px;
    color: #ffffff;
    font-size: 14.5px;
    font-weight: 400;
    padding: 2px;
  }
  .fivedivMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .lastDiveMobile {
    justify-content: center;
    display: flex;
  }
  .divSizelastdiv {
    display: flex;
    width: 290px;
    height: 126px;
  }
  .divSizeMobile {
    width: 290px;
    height: 82px;
    background-color: #444444;
    margin: 10px;
    border-radius: 10px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  .imagesss {
    margin: 10px;
  }
  .seconddiv {
    display: none;
  }
  .seconddivMobile {
    display: flex;
    flex-direction: column;
  }
  .smalimg {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .freeOnlineMobile {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
  .createfreeMobile {
    font-size: 20px;
    font-weight: 700;
    color: #3c3724;
    text-align: center;
    margin: 0px;
  }
  .merchantMobile {
    font-size: 20px;
    font-weight: 700;
    color: #ef8829;
    text-align: center;
    margin: 0px;
  }
  .enablesMobile {
    color: #3c3535;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
  }
  .threeimagesMobile {
    height: 208.28px;
    width: 224.54px;
  }
  .itemsdivMobile {
    flex-direction: column;
    display: flex;
  }

  .fivedivMobile {
    display: flex;
    flex-direction: row;
  }
  .divSizeFiveMobile {
    width: 291px;
    height: 88px;
    background-color: #444444;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .lastofalllastDesktop {
    display: none;
  }
  .lastofalllastMobileView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .soonMobile {
    color: #f4aa06;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin: 0px;
  }
  .connectinss {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin: 0px;
  }
  .downloadAppMobile {
    flex-direction: row;
    justify-content: center;
    display: flex;
    align-content: center;
  }
  .promiseMobile {
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    margin: 0px;
  }
  .NewsletterMobile {
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #262424;
  }
  .allinputfiedlMobile {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: #fadb96;
    border-radius: 5px;
  }
  .textinputfiledsMobile {
    width: 239px;
    height: 36px;
    align-items: center;
    background-color: #fadb96;
    border-width: 0px;
    /* padding: 0 10px; */
  }

  .buttonsMobile {
    width: 107px;
    height: 38px;
    background-color: #f4aa06;
    align-content: center;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .SubscribetextMobile {
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
  }
  .Oweone1mobile {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  .Oweone1imagelast {
    width: 500px;
    height: 71px;
    align-items: "center";
  }
  .allAboutUsspaage {
    display: none;
  }
  .allAboutUsspaageMobileView {
    display: block;
    align-items: center;
    justify-content: center;
  }
  .heheight {
    height: 33px;
  }
  .cccuummm {
    margin-top: 10px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .yellowDesktop {
    display: none;
  }
  .yellowTabLetDesktop {
    display: flex;
  }
  .divSizewwt {
    width: 100px;

    height: 140px;
    background-color: #444444;
    margin: 5px;
    border-radius: 10px;
    /* margin-right: 30px; */
  }
  .fivedivt {
    display: flex;
    flex-direction: row;
    z-index: 2;
    margin-right: 180px;
    /* margin-left: -35% */
  }
  .shoppingt {
    margin: 0px;
    color: #ffdf73;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  .shoppingst {
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  .experiencet {
    color: #ffffff;
    margin: 0px;
    font-size: 30px;
    font-weight: 700;
    margin-left: 165px;
  }
  .wet {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    text-align: justify;
    margin-right: 50px;
  }
  .threeImagett {
    padding: 30px;
  }

  .imagesize {
    height: 567.05px;
    width: 455px;
  }

  .seconddivTablet {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .seconddiv {
    display: none;
  }
  .freeOnlinet {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .divSizewwt {
    width: 100px;

    height: 140px;
    background-color: #444444;
    margin: 5px;
    border-radius: 10px;
  }
  .threeimagest {
    width: 400px;
    /* height: 524px; */
    margin-left: -20px;
  }
  .imagetablet {
    width: 220px;
    justify-content: end;
  }
  .fivedit {
    display: flex;
    flex-direction: row;
    z-index: 2;
    margin-left: 10px;
  }
  .Newsletterdivt {
    display: flex;
    flex-direction: column;
    align-content: center;
    display: flex;
    flex-direction: "column";
    align-items: flex-start;
    width: 750px;
  }
  .Newslettert {
    font-size: 20px;
    font-weight: 700;
    margin: 0px;
  }
  .textinputfiledst {
    width: 750px;
    height: 65px;
    background-color: #fadb96;
    border-width: 0px;
    border-radius: 5px;
    padding: 0 10px;
  }
  .allinputfiedlt {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    background-color: #fadb96;
    width: 750px;
    height: 70px;
    border-radius: 5px;
  }
  .divtt {
    width: 400px;
    height: 297px;
    background-color: #f4aa06;
  }
  .connectingtablet {
    height: 32px;
    width: 380px;
    margin-left: 10px;
  }
  .divt {
    width: 100px;
    height: 297px;
  }
  .threeeimagest {
    margin: 0px;
  }
  .phototablet {
    width: 400px;
    margin-left: 5px;
  }
  .aboutuscomputert {
    width: 750px;
    height: 1480px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .latTopdivheightTablet {
    height: 100px;
  }
  .aboutuscomputertAbletAll {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 900px; */
    align-self: center;
    /* margin-left: 60px; */
  }
  .heightTablet {
    height: 100px;
  }
  .dotTablet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .threeimageTablet {
    width: 100%;
  }
}
