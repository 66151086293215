
.container {
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .navbar {
    height: 60px;
    position: relative;
  }
  
  .logo {
    margin-left: 90px;
    cursor: pointer;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-elements {
    margin-right: 120px;
    display: inline-block;
  
  }
  
  .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    
  }
  
  .nav-elements ul li:not(:last-child) {
    margin-right: 60px;
  }
  
  .nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: white;
    text-decoration: none;
  }
  
  .nav-elements ul a.active {
    color: #FFFFFF;
    font-weight: 500;
    position: relative;
  }
  .nav-elements ul a.active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
  }
  
  .navHeight {
    margin: 0px;
    margin-top: 15px;
  }
  
  .alrmimage{
      height: 16.33px;
      width: 18.56px;
  }
  @media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
      margin-right: 30px;
    }
    
  }
  
  @media screen and (max-width: 600px) {
    .nav-elements ul a {
      font-size: 16px;
      font-weight: 400;
      color: white;
      opacity: 0.8;
      /* text-decoration: underline; */
      border-bottom: 1px solid #000000; /* You can adjust the width by changing the value (e.g., 1px, 3px, etc.) */
      text-decoration: none;
    }
    
    .nav-elements ul a.active {
      color: yellow;
      font-weight: 500;
      position: relative;
    }
    .logo {
      margin: 0px;
      cursor: pointer;
      margin-top: 5px;
    }
  
    .menu-icon {
      display: block;
      cursor: pointer;
    }
  
    .navHeight {
      margin: 0px;
    }
    .OwenaCareers{
      color: red;
    }
    .nav-elements {
      position: absolute;
      top: 68px;
      background-color: black;
      width: 0px;
      height: 130px;
      transition: all 0.3s ease-in;
      overflow: hidden;
     
    }
  
    .nav-elements.active {
      width: 450px;
      margin-left: -15px;
    }
  
    .nav-elements ul {
      display: flex;
      flex-direction: column;
    }
  
    .nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
    }
  }